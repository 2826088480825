import { forwardRef } from "react";
import Draggable from "react-draggable";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Slide,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Paper,
} from "@mui/material";
import CustomChip from "../controls/chip";
import { CustomButton } from "../controls";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
	return (
		<Draggable handle="#title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

function WebSocketDialog({ isTestOpen, title, testData, handleClose }) {
	return (
		<Dialog
			open={isTestOpen}
			onClose={handleClose}
			TransitionComponent={Transition}
			PaperComponent={PaperComponent}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="title" sx={{ cursor: "move" }}>
				{title}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					<p>
						These are the API test results. These are just to make
						sure that your entered data is working properly &
						results are as expected. Here are the results.
					</p>
					{testData && (
						<TableContainer component={Paper} className="mt-2">
							<Table className="table-sm">
								<TableBody>
									{testData.status && (
										<TableRow>
											<TableCell>Status</TableCell>
											<TableCell>
												{testData.status === 200 ? (
													<CustomChip
														label={testData.status}
														color="success"
													/>
												) : testData.status === 500 ? (
													<CustomChip
														label={testData.status}
														color="error"
													/>
												) : testData.status === 0 ? (
													<CustomChip label="N/A" />
												) : (
													<CustomChip
														label={testData.status}
														color="warning"
													/>
												)}
											</TableCell>
										</TableRow>
									)}
									{testData.data && (
										<TableRow>
											<TableCell>
												Response Message
											</TableCell>
											<TableCell>
												{testData.data}
											</TableCell>
										</TableRow>
									)}
									{testData.status && (
										<TableRow>
											<TableCell>Active</TableCell>
											<TableCell>
												{testData.status === 200 ? (
													<span
														style={{
															color: "green",
														}}
													>
														Active
													</span>
												) : (
													<span
														style={{
															color: "red",
														}}
													>
														Inactive
													</span>
												)}
											</TableCell>
										</TableRow>
									)}
									<TableRow>
										<TableCell>Latency</TableCell>
										<TableCell>
											{testData.latency}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<CustomButton
					onClick={handleClose}
					text="Close"
					mx="15px"
					my={1}
				/>
			</DialogActions>
		</Dialog>
	);
}

export default WebSocketDialog;
