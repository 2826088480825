import { forwardRef, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Slide,
	FormControl,
	Select,
	InputLabel,
	MenuItem,
} from "@mui/material";
import CustomTextField from "../controls/textField";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function ThresholdDialog({
	isThresholdOpen,
	handleClose,
	newEndpoint,
	setNewEndpoint,
	toEdit,
}) {
	let [threshold, setThreshold] = useState(
		toEdit
			? toEdit
			: {
					type: "",
					noOfFailers: 0,
					time: 0,
			  }
	);

	const handleInputChange = (e) => {
		setThreshold({ ...threshold, [e.target.name]: e.target.value });
	};

	const handleSave = async (e) => {
		e.preventDefault();
		setNewEndpoint({
			...newEndpoint,
			thresholdType: threshold,
		});
		handleClose();
	};

	return (
		<Dialog
			open={isThresholdOpen}
			onClose={handleClose}
			TransitionComponent={Transition}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				Add Thresholds Detail here
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					<p>
						These options can help you customize how you'll receive
						warning & alert emails & message
					</p>
					<form>
						<FormControl sx={{ my: 1 }} fullWidth size="small">
							<InputLabel id="select-type-label">
								Select Threshold Type
							</InputLabel>
							<Select
								labelId="select-type-label"
								id="select-type"
								name="type"
								label="Select Threshold Type"
								value={threshold.type}
								onChange={handleInputChange}
							>
								<MenuItem value="">None</MenuItem>
								<MenuItem value="consecutive">
									Consecutive (x failers in a row)
								</MenuItem>
								<MenuItem value="time-based">
									Time-Based (x failers in y minutes)
								</MenuItem>
							</Select>
						</FormControl>
						<CustomTextField
							label="Numer of Failers after which the alert will be triggered"
							name="noOfFailers"
							type="noOfFailers"
							value={threshold.noOfFailers}
							onChange={handleInputChange}
							placeholder="Numer of Failers"
						/>
						{threshold.type === "time-based" && (
							<CustomTextField
								label="Time (minutes) in which above failers will occur in order to trigger alert"
								name="time"
								value={threshold.time}
								onChange={handleInputChange}
								placeholder="Time"
								required
							/>
						)}
					</form>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ mr: 2, mb: 1 }}>
				<Button onClick={handleClose}>Close</Button>
				<Button type="submit" onClick={handleSave}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ThresholdDialog;
