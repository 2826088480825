import { useState, useEffect } from "react";
import {
	Container,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Paper,
	TablePagination,
} from "@mui/material";
import axios from "axios";
import constants from "../constants/constants";
import useStore from "../store/store";
import { CustomTypography, CustomTextField } from "../controls";
import { Chart } from "../components/charts";
import AlertsHistory from "./AlertsHistory";

const Dashboard = () => {
	const [endpoints, setEndpoints] = useState([]);
	const [filteredEndpoints, setFilteredEndpoints] = useState([]);
	const [filter, setFilter] = useState("");
	const { token, setIsLoading } = useStore((state) => state);
	const [page, setPage] = useState(0);
	const [limit, setLimit] = useState(10);
	const [count, setCount] = useState(0);

	useEffect(() => {
		fetchEndpoints();
	}, []);

	useEffect(() => {
		if (endpoints.length === 0) return;
		setFilteredEndpoints(
			endpoints.filter(
				(endpoint) =>
					endpoint.groupName
						.toLowerCase()
						.includes(filter.toLowerCase()) ||
					endpoint.nickname
						.toLowerCase()
						.includes(filter.toLowerCase())
			)
		);
	}, [filter, endpoints]);

	const fetchEndpoints = async () => {
		setIsLoading(true);
		const headers = {};
		headers[constants.TOKEN_NAME] = token;
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_URL}/api-endpoints?page=${page}&limit=${limit}`,
				{ headers: headers }
			);
			if (res.status === 200) {
				setEndpoints(res.data.data);
				setCount(res.data.total);
				setIsLoading(false);
			}
		} catch (err) {
			console.error("Error fetching API endpoints:", err);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchEndpoints(page, limit);
	}, [page, limit]);

	const handlePageChange = (event, newPage) => {
		setPage(newPage);
	};

	const handleLimitChange = (event) => {
		setLimit(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<>
			<Container>
				<CustomTypography text="Dashboard" />

				<CustomTextField
					label={`Filter by nick name or group name`}
					value={filter}
					onChange={(e) => setFilter(e.target.value)}
				/>

				{endpoints && endpoints.length > 0 ? (
					<>
						<TableContainer component={Paper} className="mt-2">
							<Table className="table-sm">
								<TableHead>
									<TableRow>
										<TableCell>Nick Name</TableCell>
										<TableCell>Group Name</TableCell>
										<TableCell>URL</TableCell>
										<TableCell>Status</TableCell>
										<TableCell>Response Time</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{filteredEndpoints.map((endpoint) => (
										<TableRow key={endpoint._id}>
											<TableCell>
												{endpoint.nickname}
											</TableCell>
											<TableCell>
												{endpoint.groupName}
											</TableCell>
											<TableCell>
												{endpoint.url}
											</TableCell>
											<TableCell>
												{endpoint.isActive ? (
													<span
														style={{
															color: "green",
														}}
													>
														Active
													</span>
												) : (
													<span
														style={{ color: "red" }}
													>
														Inactive
													</span>
												)}
											</TableCell>
											<TableCell align="center">
												{endpoint.lastResponseTime
													? `${endpoint.lastResponseTime} ms`
													: "N/A"}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>

						<div className="mt-1">
							<TablePagination
								component="div"
								count={count}
								page={page}
								onPageChange={handlePageChange}
								rowsPerPage={limit}
								onRowsPerPageChange={handleLimitChange}
								rowsPerPageOptions={[10, 20, 30, 50]}
							/>
						</div>
					</>
				) : (
					<span>
						<i>No data found</i>
					</span>
				)}

				{endpoints && endpoints.length > 0 && (
					<Chart data={endpoints} />
				)}
			</Container>

			<AlertsHistory />
		</>
	);
};

export default Dashboard;
