import { useEffect, useState } from "react";
import { PieChart } from "@mui/x-charts/PieChart";

const statusColors = {
	200: "#4caf50",
	401: "#ff9800",
	403: "#f44336",
	404: "#2196f3",
	500: "#9c27b0",
};

function StatusCodesPieChart({ endpoints }) {
	const [data, setData] = useState([]);

	useEffect(() => {
		if (!endpoints || endpoints.length === 0) return;
		const statusCount = {};

		endpoints.forEach((endpoint) => {
			const status = endpoint.status;
			if (statusCount[status]) statusCount[status]++;
			else statusCount[status] = 1;
		});

		const pieData = Object.entries(statusCount).map(([status, count]) => ({
			value: count,
			label: `Status ${status}`,
			color: statusColors[status] || "#cccccc",
		}));

		setData(pieData);
	}, [endpoints]);

	return (
		<PieChart
			series={[
				{
					data: data,
					id: "statusPieChart",
				},
			]}
			width={800}
			height={400}
		/>
	);
}

export default StatusCodesPieChart;
