import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
	Container,
	Table,
	TableContainer,
	Paper,
	TableBody,
	TableRow,
	TableCell,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
	AccessTimeOutlined,
	EmailOutlined,
	PermIdentityOutlined,
} from "@mui/icons-material";
import useStore from "../store/store";
import { CustomTypography } from "../controls";
import ChangePassword from "./ChangePassword";

function Profile() {
	const navigate = useNavigate();
	const { user } = useStore((state) => state);

	useEffect(() => {
		if (!user) navigate("/login");
	}, []);

	return (
		<>
			<Container className="w-50">
				<Grid item size={{ xs: 12, md: 12 }}>
					<CustomTypography text="My profile" />
				</Grid>

				<Grid item sx={{ mt: 2 }} size={{ xs: 12, md: 6 }}>
					<TableContainer component={Paper}>
						<Table>
							<TableBody>
								{user.email && (
									<TableRow>
										<TableCell align="right">
											<EmailOutlined />
										</TableCell>
										<TableCell>{user.email}</TableCell>
									</TableRow>
								)}
								<TableRow>
									<TableCell align="right">
										<PermIdentityOutlined />
									</TableCell>
									<TableCell>
										{user.isAdmin ? "Admin" : "User"}
									</TableCell>
								</TableRow>
								{user.createdAt && (
									<TableRow>
										<TableCell align="right">
											<AccessTimeOutlined />
										</TableCell>
										<TableCell>{user.createdAt}</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Container>

			<ChangePassword />
		</>
	);
}

export default Profile;
