import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Key } from "@mui/icons-material";
import axios from "axios";
import constants from "../constants/constants";
import useStore from "../store/store";
import { CustomButton, CustomTextField, CustomTypography } from "../controls";

function ChangePassword() {
	const navigate = useNavigate();
	const { token, user, setIsLoading, setSuccessMessage, setErrorMessage } =
		useStore((state) => state);
	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");
	const [userData, setUserData] = useState("");

	useEffect(() => {
		getUser();
	}, []);

	const getUser = async () => {
		let isUser = window.location.href.includes("change-password");
		if (!isUser) return;

		setIsLoading(true);
		let userId = window.location.href.split("userId=").pop();

		const headers = {};
		headers[constants.TOKEN_NAME] = token;

		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/users/${userId}`,
			{ headers: headers }
		);

		if (res.status === 200) {
			setUserData(res.data);
			setIsLoading(false);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (
			!newPassword ||
			!repeatPassword ||
			(!userData && !currentPassword)
		) {
			setErrorMessage(`Please provide all fields.`);
			return;
		}

		if (newPassword !== repeatPassword) {
			setErrorMessage(`New password did not match.`);
			return;
		}

		setIsLoading(true);
		!userData ? handleSelfPasswordChange(e) : handleUserPasswordChange(e);
	};

	const handleSelfPasswordChange = async (e) => {
		e.preventDefault();

		const headers = {};
		headers[constants.TOKEN_NAME] = token;

		try {
			const res = await axios.put(
				`${process.env.REACT_APP_API_URL}/users/change-password/${user._id}`,
				{
					currentPassword,
					newPassword,
				},
				{ headers: headers }
			);
			if (res.status === 200) {
				setSuccessMessage(
					`Password updated successfully! Use new password to login next time.`
				);
				handleSuccess();
			}
		} catch (err) {
			handleError(err);
		}
	};

	const handleUserPasswordChange = async (e) => {
		e.preventDefault();
		let userId = window.location.href.split("userId=").pop();

		const headers = {};
		headers[constants.TOKEN_NAME] = token;

		try {
			const res = await axios.put(
				`${process.env.REACT_APP_API_URL}/users/change-user-password/${userId}`,
				{
					newPassword,
				},
				{ headers: headers }
			);
			if (res.status === 200) {
				setSuccessMessage(`User password updated successfully!`);
				handleSuccess();
			}
		} catch (err) {
			handleError(err);
		}
	};

	const handleSuccess = () => {
		handleResetForm();
		setIsLoading(false);
		navigate("/admin");
	};

	const handleError = (err) => {
		console.error("Error changing password:", err);
		setErrorMessage(err.response.data);
		setIsLoading(false);
	};

	const handleResetForm = () => {
		setCurrentPassword("");
		setNewPassword("");
		setRepeatPassword("");
	};

	return (
		<Container className="w-50">
			<Grid item sx={{ mt: 2 }} size={{ xs: 12, md: 12 }}>
				<CustomTypography
					text={
						userData.email
							? "Change User Password"
							: "Change Password"
					}
					variant="h6"
				/>
			</Grid>

			{userData.email && (
				<Grid item sx={{ my: 2 }} size={{ xs: 12, md: 12 }}>
					<CustomTypography
						text={`Change password for user: ${userData.email}`}
						variant="p"
					/>
				</Grid>
			)}

			{!userData.email && (
				<Grid item size={{ xs: 12, md: 12 }}>
					<CustomTextField
						label="Current Password"
						value={currentPassword}
						onChange={(e) => setCurrentPassword(e.target.value)}
					/>
				</Grid>
			)}
			<Grid item size={{ xs: 12, md: 12 }}>
				<CustomTextField
					label="New Password"
					value={newPassword}
					onChange={(e) => setNewPassword(e.target.value)}
				/>
			</Grid>
			<Grid item size={{ xs: 12, md: 12 }}>
				<CustomTextField
					label="Repeat Password"
					value={repeatPassword}
					onChange={(e) => setRepeatPassword(e.target.value)}
				/>
			</Grid>
			<Grid item size={{ xs: 12, md: 12 }}>
				<CustomButton
					text="Update Password"
					onClick={handleSubmit}
					mx="0px"
					my={1}
					icon={<Key />}
					fullWidth={true}
				/>
			</Grid>
		</Container>
	);
}

export default ChangePassword;
