import { TextField } from "@mui/material";

const CustomTextField = ({
	variant,
	fullWidth,
	label,
	name,
	type,
	value,
	onChange,
	helperText,
	required,
	sx,
	multiline,
	disabled,
	slotProps,
}) => {
	return (
		<TextField
			variant={variant || "outlined"}
			fullWidth={fullWidth || true}
			margin="normal"
			size="small"
			label={label}
			name={name}
			type={type || "text"}
			value={value}
			onChange={onChange}
			helperText={helperText}
			required={required || true}
			sx={{ ...sx, my: 1 }}
			multiline={multiline}
			disabled={disabled}
			slotProps={slotProps}
		/>
	);
};

export default CustomTextField;
