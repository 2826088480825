import { useState, useEffect } from "react";
import {
	Container,
	Box,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableContainer,
	Paper,
	TablePagination,
} from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import axios from "axios";
import constants from "../constants/constants";
import useStore from "../store/store";
import { CustomTextField, CustomButton, CustomTypography } from "../controls";
import { ConfirmDialog } from "../components";

const Groups = () => {
	const [groups, setGroups] = useState([]);
	const [newGroup, setNewGroup] = useState({
		_id: null,
		name: "",
	});
	const [filter, setFilter] = useState("");
	const [filteredGroups, setFilteredGroups] = useState([]);
	const {
		token,
		setIsLoading,
		toEdit,
		setToEdit,
		setSuccessMessage,
		setInfoMessage,
		setErrorMessage,
		user,
	} = useStore((state) => state);
	let [isConfirmOpen, setIsConfirmOpen] = useState(false);
	const [page, setPage] = useState(0);
	const [limit, setLimit] = useState(10);
	const [count, setCount] = useState(0);

	useEffect(() => {
		fetchGroups();
	}, []);

	const fetchGroups = async () => {
		setIsLoading(true);
		const headers = {};
		headers[constants.TOKEN_NAME] = token;
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_URL}/groups?page=${page}&limit=${limit}`,
				{ headers: headers }
			);
			if (res.status === 200) {
				setGroups(res.data.data);
				setCount(res.data.total);
				setIsLoading(false);
			}
		} catch (err) {
			console.error("Error fetching groups:", err);
			setIsLoading(false);
		}
	};

	const handleInputChange = (e) => {
		setNewGroup({ ...newGroup, [e.target.name]: e.target.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const headers = {};
		headers[constants.TOKEN_NAME] = token;
		if (newGroup._id === null) {
			try {
				const res = await axios.post(
					`${process.env.REACT_APP_API_URL}/groups`,
					newGroup,
					{ headers: headers }
				);
				if (res.status === 200) {
					setSuccessMessage("Group has been created successfully");
					fetchGroups();
					handleResetGroup();
				}
			} catch (err) {
				console.error("Group", err);
				setErrorMessage("Error creating group. Please try again.");
			}
		} else {
			try {
				const res = await axios.put(
					`${process.env.REACT_APP_API_URL}/groups/${newGroup._id}`,
					newGroup,
					{ headers: headers }
				);
				if (res.status === 200) {
					fetchGroups();
					setInfoMessage("Group has been updated successfully");
					handleResetGroup();
				}
			} catch (err) {
				setErrorMessage("Error updating group. Please try again.");
				console.error("Error updating group:", err);
			}
		}
	};

	const handleEditGroup = async (group) => {
		setNewGroup({
			_id: group._id,
			name: group.name,
		});
	};

	const handleDeleteGroup = async () => {
		setIsLoading(true);
		const headers = {};
		headers[constants.TOKEN_NAME] = token;
		try {
			let res = await axios.delete(
				`${process.env.REACT_APP_API_URL}/groups/${toEdit._id}`,
				{ headers: headers }
			);
			if (res) {
				fetchGroups();
				setInfoMessage("Group has been deleted successfully");
				setToEdit(null);
				handleResetGroup();
				setIsLoading(false);
				setIsConfirmOpen(false);
			}
		} catch (err) {
			console.error("Error deleting group:", err);
			setIsLoading(false);
			setErrorMessage("Error deleting group. Please try again.");
			setIsConfirmOpen(false);
		}
	};

	const handleResetGroup = () => {
		setNewGroup({
			_id: null,
			name: "",
		});
	};

	useEffect(() => {
		setFilteredGroups(
			groups.filter((group) =>
				group.name.toLowerCase().includes(filter.toLowerCase())
			)
		);
	}, [filter, groups]);

	useEffect(() => {
		fetchGroups(page, limit);
	}, [page, limit]);

	const handlePageChange = (event, newPage) => {
		setPage(newPage);
	};

	const handleLimitChange = (event) => {
		setLimit(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<>
			<Container className="w-50">
				<CustomTypography text="Groups" />

				{user.isAdmin && (
					<Box component="form" noValidate>
						<div className="row">
							<div className="col-8">
								<CustomTextField
									label="Group Name"
									name="name"
									value={newGroup.name}
									onChange={handleInputChange}
									fullWidth={true}
								/>
							</div>
							<div className="col-4 d-flex flex-row-reverse p-0">
								<CustomButton
									text={
										newGroup._id === null
											? "Add Group"
											: "Update Group"
									}
									size="normal"
									onClick={handleSubmit}
									my={1}
									fullWidth={true}
									mx="12px"
									icon={<AddOutlined />}
								/>
							</div>
						</div>
					</Box>
				)}

				<CustomTextField
					label={`Filter by nick name or group name`}
					value={filter}
					onChange={(e) => setFilter(e.target.value)}
				/>

				{groups && groups.length > 0 ? (
					<>
						<TableContainer
							component={Paper}
							className="mt-2 overflow-hidden"
						>
							<Table className="table-sm">
								<TableHead>
									<TableRow>
										<TableCell>Group Name</TableCell>
										{user.isAdmin && (
											<TableCell
												align="right"
												style={{ paddingRight: "12%" }}
											>
												Actions
											</TableCell>
										)}
									</TableRow>
								</TableHead>
								<TableBody>
									{filteredGroups.map((group) => (
										<TableRow key={group._id}>
											<TableCell>{group.name}</TableCell>
											{user.isAdmin && (
												<TableCell align="right">
													<CustomButton
														color="info"
														text="Edit"
														onClick={() =>
															handleEditGroup(
																group
															)
														}
													/>
													<CustomButton
														color="error"
														text="Delete"
														onClick={() => {
															setIsConfirmOpen(
																true
															);
															setToEdit(group);
														}}
													/>
												</TableCell>
											)}
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>

						<div className="mt-1">
							<TablePagination
								component="div"
								count={count}
								page={page}
								onPageChange={handlePageChange}
								rowsPerPage={limit}
								onRowsPerPageChange={handleLimitChange}
								rowsPerPageOptions={[
									10,
									20,
									30,
									50,
									{
										label: "All",
										value: -1,
									},
								]}
							/>
						</div>
					</>
				) : (
					<span>
						<i>No data found</i>
					</span>
				)}
			</Container>

			{isConfirmOpen && (
				<ConfirmDialog
					isConfirmOpen={isConfirmOpen}
					title="Are you sure?"
					body="Are you sure you want to delete this record? This action can't be undone."
					onNo={() => setIsConfirmOpen(false)}
					onYes={() => handleDeleteGroup()}
				/>
			)}
		</>
	);
};

export default Groups;
