import { useState, useEffect } from "react";
import {
	Container,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TablePagination,
} from "@mui/material";
import axios from "axios";
import constants from "../constants/constants";
import useStore from "../store/store";
import { CustomTypography, CustomTextField } from "../controls";

const Messages = () => {
	const { token, setIsLoading } = useStore((state) => state);
	const [messages, setMessages] = useState([]);
	const [query, setQuery] = useState("");
	const [filteredMessages, setFilteredMessages] = useState([]);
	const [page, setPage] = useState(0);
	const [limit, setLimit] = useState(10);
	const [count, setCount] = useState(0);

	const fetchMessages = async () => {
		setIsLoading(true);
		const headers = {};
		headers[constants.TOKEN_NAME] = token;
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_URL}/messages?page=${page}&limit=${limit}`,
				{ headers: headers }
			);
			if (res.status === 200) {
				setMessages(res.data.data);
				setCount(res.data.total);
				setIsLoading(false);
			}
		} catch (err) {
			console.error("Error fetching WebSocket messages:", err);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		setFilteredMessages(messages);
		if (messages.length === 0) return;

		setFilteredMessages(
			messages.filter(
				(msg) =>
					msg.webSocket.nickname
						.toLowerCase()
						.includes(query.toLowerCase()) ||
					msg.webSocket.url
						.toLowerCase()
						.includes(query.toLowerCase())
			)
		);
	}, [query, messages]);

	useEffect(() => {
		fetchMessages(page, limit);
	}, [page, limit]);

	const handlePageChange = (event, newPage) => {
		setPage(newPage);
	};

	const handleLimitChange = (event) => {
		setLimit(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<Container>
			<CustomTypography text="WebSocket Messages" />

			<CustomTextField
				label="Filter by nick name"
				value={query}
				onChange={(e) => setQuery(e.target.value)}
			/>

			{messages && messages.length > 0 ? (
				<>
					<TableContainer component={Paper} className="mt-2">
						<Table className="table-sm">
							<TableHead>
								<TableRow>
									<TableCell>WebSocket Nickname</TableCell>
									<TableCell>WebSocket URL</TableCell>
									<TableCell>WebSocket Latency</TableCell>
									<TableCell>Request Message</TableCell>
									<TableCell>Response Message</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{filteredMessages.map((message) => (
									<TableRow key={message._id}>
										<TableCell>
											{message.webSocket.nickname}
										</TableCell>
										<TableCell>
											{message.webSocket.url}
										</TableCell>
										<TableCell>
											{message.webSocket.latency
												? `${message.webSocket.latency} ms`
												: "-"}
										</TableCell>
										<TableCell>
											{message.reqMsg
												? message.reqMsg
												: "-"}
										</TableCell>
										<TableCell>
											{message.resMsg
												? message.resMsg
												: "-"}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						component="div"
						count={count}
						page={page}
						onPageChange={handlePageChange}
						rowsPerPage={limit}
						onRowsPerPageChange={handleLimitChange}
						rowsPerPageOptions={[10, 20, 30, 50]}
					/>
				</>
			) : (
				<span>
					<i>No data found</i>
				</span>
			)}
		</Container>
	);
};

export default Messages;
