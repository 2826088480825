import React from "react";

const CustomSqChip = ({ label, color }) => {
	return (
		<div
			style={{
				color: "white",
				backgroundColor: color,
				padding: "2px",
				border: "1px solid",
				width: "50px",
				textAlign: "center",
				alignItems: "center",
				borderRadius: "4px",
			}}
		>
			{label}
		</div>
	);
};

export default CustomSqChip;
