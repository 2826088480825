import { Button } from "@mui/material";

const CustomButton = ({
	variant,
	color,
	onClick,
	size,
	text,
	mx,
	my,
	sx,
	fullWidth,
	className,
	icon,
}) => {
	return (
		<Button
			variant={variant || "outlined"}
			color={color || "primary"}
			onClick={onClick}
			size={size || "small"}
			sx={{ mx: mx || 1, my: my, ...sx }}
			fullWidth={fullWidth || false}
			className={className}
			startIcon={icon}
		>
			{text}
		</Button>
	);
};

export default CustomButton;
