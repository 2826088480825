import { useEffect, useState } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";

const otherSetting = {
	height: 400,
	yAxis: [{ label: "Response Time (s)" }],
	grid: { horizontal: true },
	sx: {
		[`& .${axisClasses.left} .${axisClasses.label}`]: {
			transform: "translateX(-10px)",
		},
		[`& .${axisClasses.bottom} .${axisClasses.label}`]: {
			transform: "translateY(10px)",
		},
	},
};

function ResponseTimeBarChart({ endpoints }) {
	const [uData, setUData] = useState([]);
	const [xLabels, setXLabels] = useState([]);

	useEffect(() => {
		if (!endpoints || endpoints.length === 0) return;

		const data = [];
		const labels = [];
		endpoints.forEach((endpoint, index) => {
			data.push(endpoint.lastResponseTime / 1000);
			labels.push(`${endpoint.nickname} (${index + 1})`);
		});

		setUData(data);
		setXLabels(labels);
	}, [endpoints]);

	return (
		<div style={{ overflowX: "auto" }}>
			<BarChart
				width={Math.max(800, xLabels.length * 80)}
				series={[
					{
						data: uData,
						label: "Response Time (seconds)",
						id: "responseTime",
					},
				]}
				xAxis={[
					{
						id: "barCategories",
						data: xLabels,
						scaleType: "band",
						tickLabelStyle: {
							angle: 18,
							textAnchor: "start",
							fontSize: 10,
						},
					},
				]}
				{...otherSetting}
			/>
		</div>
	);
}

export default ResponseTimeBarChart;
