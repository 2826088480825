import React from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import useStore from "./store/store";
import {
	SuccessMessage,
	InfoMessage,
	ErrorMessage,
} from "./components/messages";
import Header from "./components/header";
import Loading from "./controls/loader";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import Endpoints from "./pages/Endpoints";
import TestingHistory from "./pages/TestingHistory";
import AlertsHistory from "./pages/AlertsHistory";
import Alerts from "./pages/Alerts";
import AddNewEndpoint from "./pages/AddEndpoint";
import Groups from "./pages/Group";
import TestDetails from "./pages/TestDetails";
import CreateUser from "./pages/CreateUser";
import Profile from "./pages/Profile";
import ChangePassword from "./pages/ChangePassword";
import AddNewWebSocket from "./pages/AddWebsocket";
import WebSocketComp from "./pages/Websockets";

function App() {
	const {
		user,
		isLoading,
		successMessage,
		setSuccessMessage,
		infoMessage,
		setInfoMessage,
		errorMessage,
		setErrorMessage,
	} = useStore((state) => state);

	return (
		<Router>
			<Header />
			{!user && <Navigate to="/login" replace />}
			{isLoading === true && <Loading />}
			<SuccessMessage
				message={successMessage}
				open={successMessage.length > 1}
				handleClose={() => setSuccessMessage("")}
			/>
			<InfoMessage
				message={infoMessage}
				open={infoMessage.length > 1}
				handleClose={() => setInfoMessage("")}
			/>
			<ErrorMessage
				message={errorMessage}
				open={errorMessage.length > 1}
				handleClose={() => setErrorMessage("")}
			/>
			<div className="App">
				<div className="container mt-4">
					<Routes>
						<Route path="/" element={<Dashboard />} />
						<Route path="/login" element={<Login />} />
						<Route path="/admin" element={<Admin />} />
						<Route path="/api-endpoints" element={<Endpoints />} />
						<Route
							path="/alerts-history"
							element={<AlertsHistory />}
						/>
						<Route path="/alerts" element={<Alerts />} />
						<Route path="/groups" element={<Groups />} />
						<Route path="/websockets" element={<WebSocketComp />} />
						<Route
							path="/testing-history"
							element={<TestingHistory />}
						/>
						<Route
							path="/add-new-endpoint"
							element={<AddNewEndpoint />}
						/>
						<Route
							path="/add-new-websocket"
							element={<AddNewWebSocket />}
						/>
						<Route path="/test-details" element={<TestDetails />} />
						<Route path="/create-user" element={<CreateUser />} />
						<Route path="/profile" element={<Profile />} />
						<Route
							path="/change-password"
							element={<ChangePassword />}
						/>
						<Route path="*" element={<Navigate to="/" replace />} />
					</Routes>
				</div>
			</div>
		</Router>
	);
}

export default App;
