import { useState, useEffect } from "react";
import {
	Container,
	Box,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableContainer,
	Paper,
	TablePagination,
} from "@mui/material";
import { CheckOutlined } from "@mui/icons-material";
import axios from "axios";
import constants from "../constants/constants";
import useStore from "../store/store";
import { CustomTextField, CustomButton, CustomTypography } from "../controls";
import { ConfirmDialog } from "../components";

const Alerts = () => {
	const {
		token,
		setIsLoading,
		toEdit,
		setToEdit,
		setSuccessMessage,
		setInfoMessage,
		setErrorMessage,
		user,
	} = useStore((state) => state);
	const [alerts, setAlerts] = useState([]);
	const [newAlert, setNewAlert] = useState({
		_id: null,
		cellPhoneNumbers: "",
		emailAddresses: "",
		failureThreshold: "",
		failurePeriod: "",
	});
	let [isConfirmOpen, setIsConfirmOpen] = useState(false);
	const [page, setPage] = useState(0);
	const [limit, setLimit] = useState(10);
	const [count, setCount] = useState(0);

	useEffect(() => {
		fetchAlerts();
	}, []);

	const fetchAlerts = async () => {
		setIsLoading(true);
		const headers = {};
		headers[constants.TOKEN_NAME] = token;
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_URL}/alerts?page=${page}&limit=${limit}`,
				{ headers: headers }
			);
			if (res.status === 200) {
				setAlerts(res.data.data);
				setCount(res.data.total);
				setIsLoading(false);
			}
		} catch (err) {
			console.error("Error fetching alerts:", err);
			setIsLoading(false);
		}
	};

	const handleInputChange = (e) => {
		setNewAlert({ ...newAlert, [e.target.name]: e.target.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const headers = {};
		headers[constants.TOKEN_NAME] = token;
		if (newAlert._id === null) {
			try {
				const res = await axios.post(
					`${process.env.REACT_APP_API_URL}/alerts`,
					newAlert,
					{ headers: headers }
				);
				if (res.status === 200) {
					fetchAlerts();
					setSuccessMessage("Alert has been saved successfully");
					handleResetAlert();
				}
			} catch (err) {
				setErrorMessage("Error saving alert. Please try again.");
				console.error("Error creating alert:", err);
			}
		} else {
			try {
				const res = await axios.put(
					`${process.env.REACT_APP_API_URL}/alerts/${newAlert._id}`,
					newAlert
				);
				if (res.status === 200) {
					fetchAlerts();
					setInfoMessage("Alert has been updated successfully");
					handleResetAlert();
				}
			} catch (err) {
				setErrorMessage("Error updating alert. Please try again.");
				console.error("Error updating alert:", err);
			}
		}
	};

	const handleEditAlert = async (alert) => {
		setNewAlert({
			_id: alert._id,
			cellPhoneNumbers: alert.cellPhoneNumbers.join(", "),
			emailAddresses: alert.emailAddresses.join(", "),
			failureThreshold: alert.failureThreshold,
			failurePeriod: alert.failurePeriod,
		});
	};

	const handleDeleteAlert = async () => {
		setIsLoading(true);
		const headers = {};
		headers[constants.TOKEN_NAME] = token;
		try {
			await axios.delete(
				`${process.env.REACT_APP_API_URL}/alerts/${toEdit._id}`,
				{ headers: headers }
			);
			fetchAlerts();
			setInfoMessage("Alert has been deleted successfully");
			setToEdit(null);
			handleResetAlert();
			setIsLoading(false);
			setIsConfirmOpen(false);
		} catch (err) {
			setErrorMessage("Error deleting alert. Please try again.");
			console.error("Error deleting alert:", err);
			setIsLoading(false);
			setIsConfirmOpen(false);
		}
	};

	const handleResetAlert = () => {
		setNewAlert({
			_id: null,
			cellPhoneNumbers: "",
			emailAddresses: "",
			failureThreshold: "",
			failurePeriod: "",
		});
	};

	useEffect(() => {
		fetchAlerts(page, limit);
	}, [page, limit]);

	const handlePageChange = (event, newPage) => {
		setPage(newPage);
	};

	const handleLimitChange = (event) => {
		setLimit(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<>
			<Container>
				<CustomTypography text="Alerts" />

				{user.isAdmin && (
					<Box component="form" noValidate>
						<CustomTextField
							label="Cell Phone Numbers (comma-separated)"
							name="cellPhoneNumbers"
							value={newAlert.cellPhoneNumbers}
							onChange={handleInputChange}
							fullWidth={true}
						/>
						<CustomTextField
							label="Email Addresses (comma-separated)"
							name="emailAddresses"
							value={newAlert.emailAddresses}
							onChange={handleInputChange}
							fullWidth={true}
						/>

						<div className="row">
							<div className="col-6">
								<CustomTextField
									label="Failure Threshold"
									name="failureThreshold"
									type="number"
									value={newAlert.failureThreshold}
									onChange={handleInputChange}
									fullWidth={true}
								/>
							</div>
							<div className="col-6">
								<CustomTextField
									label="Failure Period"
									name="failurePeriod"
									type="number"
									value={newAlert.failurePeriod}
									onChange={handleInputChange}
									fullWidth={true}
								/>
							</div>
						</div>
						<CustomButton
							text={
								newAlert._id === null
									? "Add Alert"
									: "Update Alert"
							}
							size="normal"
							onClick={handleSubmit}
							my={1}
							mx="0px"
							fullWidth={true}
							icon={<CheckOutlined />}
						/>
					</Box>
				)}

				{alerts && alerts.length > 0 && (
					<>
						<TableContainer component={Paper} className="mt-4">
							<Table className="table-sm">
								<TableHead className="text-center">
									<TableRow>
										<TableCell>
											Cell Phone Numbers
										</TableCell>
										<TableCell>Email Addresses</TableCell>
										<TableCell>Failure Threshold</TableCell>
										<TableCell>Failure Period</TableCell>
										<TableCell>Actions</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{alerts.map((alert) => (
										<TableRow key={alert._id}>
											<TableCell>
												{alert.cellPhoneNumbers.join(
													", "
												)}
											</TableCell>
											<TableCell>
												{alert.emailAddresses.join(
													", "
												)}
											</TableCell>
											<TableCell>
												{alert.failureThreshold}
											</TableCell>
											<TableCell>
												{alert.failurePeriod} minutes
											</TableCell>
											<TableCell>
												<CustomButton
													color="info"
													text="Edit"
													onClick={() =>
														handleEditAlert(alert)
													}
												/>
												<CustomButton
													color="error"
													text="Delete"
													onClick={() => {
														setIsConfirmOpen(true);
														setToEdit(alert);
													}}
												/>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>

						<div className="mt-1">
							<TablePagination
								component="div"
								count={count}
								page={page}
								onPageChange={handlePageChange}
								rowsPerPage={limit}
								onRowsPerPageChange={handleLimitChange}
								rowsPerPageOptions={[10, 20, 30, 50]}
							/>
						</div>
					</>
				)}
			</Container>

			{isConfirmOpen && (
				<ConfirmDialog
					isConfirmOpen={isConfirmOpen}
					title="Are you sure?"
					body="Are you sure you want to delete this record? This action can't be undone."
					onNo={() => setIsConfirmOpen(false)}
					onYes={() => handleDeleteAlert()}
				/>
			)}
		</>
	);
};

export default Alerts;
