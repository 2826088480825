import { NavLink } from "react-router-dom";
import useStore from "../store/store";
import Session from "../store/session";

const Header = () => {
	const { user, setUser } = useStore((state) => state);

	const handleLogout = () => {
		Session.logout();
		setUser(null);
		window.location.href = "/login";
	};

	return (
		<nav className="navbar navbar-expand-lg navbar-dark bg-primary">
			<NavLink className="navbar-brand" to="/" exact>
				API Monitoring Service
			</NavLink>
			<div className="collapse navbar-collapse">
				<ul className="navbar-nav ml-auto">
					{user && (
						<>
							<li className="nav-item">
								<NavLink
									className="nav-link"
									to="/admin"
									activeclassname="active"
								>
									Admin
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink
									className="nav-link"
									to="/"
									exact
									activeclassname="active"
								>
									Dashboard
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink
									className="nav-link"
									to="/api-endpoints"
									activeclassname="active"
								>
									API Endpoints
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink
									className="nav-link"
									to="/testing-history"
									activeclassname="active"
								>
									Testing History
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink
									className="nav-link"
									to="/groups"
									activeclassname="active"
								>
									Groups
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink
									className="nav-link"
									to="/alerts-history"
									activeclassname="active"
								>
									Alerts
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink
									className="nav-link"
									to="/websockets"
									activeclassname="active"
								>
									WebSockets
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink
									className="nav-link"
									to="/profile"
									activeclassname="active"
								>
									Profile
								</NavLink>
							</li>
							<li className="nav-item">
								<button
									className="nav-link btn btn-link"
									onClick={handleLogout}
								>
									Logout
								</button>
							</li>
						</>
					)}
					{!user && (
						<li className="nav-item">
							<NavLink
								className="nav-link"
								to="/login"
								activeclassname="active"
							>
								Login
							</NavLink>
						</li>
					)}
				</ul>
			</div>
		</nav>
	);
};

export default Header;
