import { Snackbar, Alert, Box, AlertTitle } from "@mui/material";

const InfoMessage = ({ message, open, handleClose, title = "Info" }) => {
	return (
		<Box sx={{ width: 500 }}>
			<Snackbar
				autoHideDuration={4000}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				open={open}
				onClose={handleClose}
			>
				<Alert severity="info">
					<AlertTitle>{title}</AlertTitle>
					{message}
				</Alert>
			</Snackbar>
		</Box>
	);
};

export default InfoMessage;
