import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
	Container,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TablePagination,
} from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import axios from "axios";
import constants from "../constants/constants";
import useStore from "../store/store";
import {
	CustomButton,
	CustomTypography,
	CustomChip,
	CustomTextField,
} from "../controls";
import { ConfirmDialog } from "../components";
import Messages from "./Messages";

const WebSocketComp = () => {
	const navigate = useNavigate();
	const {
		token,
		setIsLoading,
		toEdit,
		setToEdit,
		setSuccessMessage,
		setErrorMessage,
		setInfoMessage,
		user,
	} = useStore((state) => state);
	const [webSockets, setWebSockets] = useState([]);
	const [query, setQuery] = useState("");
	const [filteredWebSockets, setFilteredWebSockets] = useState([]);
	const [page, setPage] = useState(0);
	const [limit, setLimit] = useState(10);
	const [count, setCount] = useState(0);
	const [isConfirmOpen, setIsConfirmOpen] = useState(false);

	const fetchWebSockets = async () => {
		setIsLoading(true);
		const headers = {};
		headers[constants.TOKEN_NAME] = token;
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_URL}/websockets?page=${page}&limit=${limit}`,
				{ headers: headers }
			);
			if (res.status === 200) {
				setWebSockets(res.data.data);
				setCount(res.data.total);
				setIsLoading(false);
			}
		} catch (err) {
			console.error("Error fetching WebSocket webSockets:", err);
			setIsLoading(false);
		}
	};

	const handleEditWebSocket = async (endpoint) => {
		setToEdit(endpoint);
		navigate("/add-new-websocket");
	};

	const handleDeleteWebSocket = async () => {
		setIsLoading(true);
		const headers = {};
		headers[constants.TOKEN_NAME] = token;
		try {
			const res = await axios.delete(
				`${process.env.REACT_APP_API_URL}/websockets/${toEdit._id}`,
				{ headers: headers }
			);
			if (res.status === 200) {
				fetchWebSockets();
				setInfoMessage(
					"Endpoint & its message history deleted successfully"
				);
				setToEdit(null);
				setIsLoading(false);
				setIsConfirmOpen(false);
			}
		} catch (err) {
			console.error("Error deleting endpoint:", err);
			setErrorMessage("Error deleting endpoint. Please try again.");
			setIsLoading(false);
			setIsConfirmOpen(false);
		}
	};

	const handleTestWebSocket = async (id) => {
		setIsLoading(true);
		const headers = {};
		headers[constants.TOKEN_NAME] = token;
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_API_URL}/websockets/${id}/test`,
				{ headers: headers }
			);

			if (res.status === 200) setSuccessMessage(res.data.msg);
			else setInfoMessage(res.data.msg);

			fetchWebSockets();
			setIsLoading(false);
		} catch (err) {
			console.error("Error testing WebSocket endpoint:", err);
			setErrorMessage("Request failed. Please try again.");
			setIsLoading(false);
		}
	};

	useEffect(() => {
		setFilteredWebSockets(webSockets);
		if (webSockets.length === 0) return;

		setFilteredWebSockets(
			webSockets.filter((endpoint) =>
				endpoint.nickname.toLowerCase().includes(query.toLowerCase())
			)
		);
	}, [query, webSockets]);

	useEffect(() => {
		fetchWebSockets(page, limit);
	}, [page, limit]);

	const handlePageChange = (event, newPage) => {
		setPage(newPage);
	};

	const handleLimitChange = (event) => {
		setLimit(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<>
			<Container>
				<CustomTypography text="WebSocket" />

				{user.isAdmin && (
					<div style={{ textAlign: "right" }}>
						<CustomButton
							onClick={() => {
								setToEdit(null);
								navigate("/add-new-websocket");
							}}
							text="Add WebSocket"
							mx="0px"
							icon={<AddOutlined />}
						/>
					</div>
				)}

				<CustomTextField
					label="Filter by nick name"
					value={query}
					onChange={(e) => setQuery(e.target.value)}
				/>

				{webSockets && webSockets.length > 0 ? (
					<>
						<TableContainer component={Paper} className="mt-2">
							<Table className="table-sm">
								<TableHead>
									<TableRow>
										<TableCell>Nickname</TableCell>
										<TableCell>URL</TableCell>
										<TableCell>Status</TableCell>
										<TableCell>Last Latency</TableCell>
										<TableCell>Last Message</TableCell>
										<TableCell align="center">
											Actions
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{filteredWebSockets.map((socket) => (
										<TableRow key={socket._id}>
											<TableCell>
												{socket.nickname}
											</TableCell>
											<TableCell>{socket.url}</TableCell>
											<TableCell>
												{socket.status === 200 ? (
													<CustomChip
														label="Connected"
														color="success"
													/>
												) : (
													<CustomChip
														label="Disconnected"
														color="error"
													/>
												)}
											</TableCell>
											<TableCell>
												{socket.latency
													? `${socket.latency} ms`
													: "-"}
											</TableCell>
											<TableCell>
												{socket.data
													? socket.data
													: "-"}
											</TableCell>
											<TableCell align="center">
												<CustomButton
													text="Test"
													color="secondary"
													onClick={() =>
														handleTestWebSocket(
															socket._id
														)
													}
												/>
												{user.isAdmin && (
													<CustomButton
														text="Edit"
														onClick={() =>
															handleEditWebSocket(
																socket
															)
														}
													/>
												)}
												{user.isAdmin && (
													<CustomButton
														text="Delete"
														color="error"
														onClick={() => {
															setIsConfirmOpen(
																true
															);
															setToEdit(socket);
														}}
													/>
												)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							component="div"
							count={count}
							page={page}
							onPageChange={handlePageChange}
							rowsPerPage={limit}
							onRowsPerPageChange={handleLimitChange}
							rowsPerPageOptions={[10, 20, 30, 50]}
						/>
					</>
				) : (
					<span>
						<i>No data found</i>
					</span>
				)}
			</Container>

			<Messages />

			{isConfirmOpen && (
				<ConfirmDialog
					isConfirmOpen={isConfirmOpen}
					title="Are you sure?"
					body="Are you sure you want to delete this record? This will delete the WebSocket endpoint along with its message history. This action can't be undone!"
					onNo={() => setIsConfirmOpen(false)}
					onYes={() => handleDeleteWebSocket()}
				/>
			)}
		</>
	);
};

export default WebSocketComp;
