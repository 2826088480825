import { Typography } from "@mui/material";

const CustomTypography = ({ text, variant, className }) => {
	return (
		<Typography variant={variant || "h5"} className={className || ""}>
			{text}
		</Typography>
	);
};

export default CustomTypography;
