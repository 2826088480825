import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

function Loading() {
	return (
		<Backdrop
			sx={{
				color: "#fff",
				zIndex: (theme) => theme.zIndex.drawer + 9999,
			}}
			open={true}
		>
			<CircularProgress
				color="inherit"
				size={70}
				thickness={2}
				variant="indeterminate"
			/>
		</Backdrop>
	);
}

export default Loading;
