import { forwardRef, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Slide,
} from "@mui/material";
import axios from "axios";
import useStore from "../store/store";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function BearerDialog({
	isBearerOpen,
	handleClose,
	newEndpoint,
	setNewEndpoint,
}) {
	let [client, setClient] = useState({
		url: "",
		client_id: "",
		client_secret: "",
		grant_type: "",
	});
	let { setIsLoading, setSuccessMessage, SetErrorMessage } = useStore(
		(state) => state
	);

	const handleInputChange = (e) => {
		setClient({ ...client, [e.target.name]: e.target.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		try {
			let res = await axios.post(
				`${process.env.REACT_APP_API_URL}/api-endpoints/getBearerToken`,
				client
			);
			if (res.status === 200) {
				const token = res.data.data.access_token;
				setNewEndpoint({
					...newEndpoint,
					bearerToken: token,
					authentication: `Bearer ${token}`,
				});
				setSuccessMessage("Bearer Token fetched successfully");
				handleClose();
				setIsLoading(false);
			}
		} catch (err) {
			console.error("Bearer Dialog", err);
			SetErrorMessage("Error fetching bearer token. Please try again.");
			setIsLoading(false);
		}
	};

	return (
		<Dialog
			open={isBearerOpen}
			onClose={handleClose}
			TransitionComponent={Transition}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				Add Your Google Console Information
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					<p>
						You can get these credentials by visiting your Google
						Developers Console account.
					</p>
					<form onSubmit={handleSubmit}>
						<div className="form-group">
							<label htmlFor="url">API link</label>
							<input
								type="text"
								className="form-control mt-1"
								id="url"
								name="url"
								value={client.url}
								onChange={handleInputChange}
								placeholder="url"
								required
							/>
						</div>
						<div className="form-group">
							<label htmlFor="client_id">Client Id</label>
							<input
								type="text"
								className="form-control mt-1"
								id="client_id"
								name="client_id"
								value={client.client_id}
								onChange={handleInputChange}
								placeholder="client_id"
								required
							/>
						</div>
						<div className="form-group">
							<label htmlFor="client_secret">Client Secret</label>
							<input
								type="text"
								className="form-control mt-1"
								id="client_secret"
								name="client_secret"
								value={client.client_secret}
								onChange={handleInputChange}
								placeholder="client_secret"
								required
							/>
						</div>
						<div className="form-group">
							<label htmlFor="grant_type">Grant Type</label>
							<input
								type="text"
								className="form-control mt-1"
								id="grant_type"
								name="grant_type"
								value={client.grant_type}
								onChange={handleInputChange}
								placeholder="grant_type"
								required
							/>
						</div>
					</form>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ mr: 2, mb: 1 }}>
				<Button onClick={handleClose}>Close</Button>
				<Button type="submit" onClick={handleSubmit}>
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default BearerDialog;
