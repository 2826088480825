import { useEffect, useState } from "react";
import { PieChart } from "@mui/x-charts/PieChart";

function IsActivePieChart({ endpoints }) {
	const [data, setData] = useState([]);

	useEffect(() => {
		if (!endpoints || endpoints.length === 0) return;
		const activeCount = endpoints.filter(
			(endpoint) => endpoint.status === 200
		).length;
		const inactiveCount = endpoints.filter(
			(endpoint) => endpoint.status !== 200
		).length;

		const pieData = [
			{ value: activeCount, label: "Active Endpoints" },
			{ value: inactiveCount, label: "Inactive Endpoints" },
		];

		setData(pieData);
	}, [endpoints]);

	return (
		<PieChart
			series={[
				{
					data: data,
					id: "isActivePieChart",
				},
			]}
			width={800}
			height={400}
		/>
	);
}

export default IsActivePieChart;
