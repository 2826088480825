import { forwardRef, Fragment } from "react";
import Draggable from "react-draggable";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Slide,
	Paper,
} from "@mui/material";
import { CustomButton } from "../controls";
import { CloseOutlined, CheckOutlined } from "@mui/icons-material";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
	return (
		<Draggable handle="#title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

function ConfirmDialog({
	isConfirmOpen,
	title,
	body,
	onNo,
	onYes,
	noText = "NO, take me back",
	yesText = "YES, delete this record",
}) {
	return (
		<Fragment>
			<Dialog
				open={isConfirmOpen}
				onClose={onNo}
				TransitionComponent={Transition}
				PaperComponent={PaperComponent}
				aria-labelledby="title"
				aria-describedby="body"
				maxWidth="sm"
				fullWidth={true}
			>
				<DialogTitle id="title" sx={{ cursor: "move" }}>
					{title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="body">{body}</DialogContentText>
				</DialogContent>
				<DialogActions sx={{ mr: 2, mb: 1 }}>
					<CustomButton
						onClick={onNo}
						text={noText}
						color="error"
						icon={<CloseOutlined />}
					/>
					<CustomButton
						onClick={onYes}
						text={yesText}
						icon={<CheckOutlined />}
					/>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
}

export default ConfirmDialog;
