import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
	Container,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableContainer,
	Paper,
	TablePagination,
} from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import axios from "axios";
import constants from "../constants/constants";
import useStore from "../store/store";
import { CustomTextField, CustomButton, CustomTypography } from "../controls";
import ConfirmDialog from "../components/confirmDialog";

const Users = () => {
	const navigate = useNavigate();
	const [users, setUsers] = useState([]);
	const [filter, setFilter] = useState("");
	const [filteredUsers, setFilteredUsers] = useState([]);
	const {
		token,
		user,
		setIsLoading,
		toEdit,
		setToEdit,
		setInfoMessage,
		setErrorMessage,
	} = useStore((state) => state);
	let [isConfirmOpen, setIsConfirmOpen] = useState(false);
	const [page, setPage] = useState(0);
	const [limit, setLimit] = useState(10);
	const [count, setCount] = useState(0);

	const fetchUsers = async (page, limit) => {
		setIsLoading(true);
		const headers = {};
		headers[constants.TOKEN_NAME] = token;
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_URL}/users?page=${page}&limit=${limit}`,
				{ headers: headers }
			);
			if (res.status === 200) {
				setUsers(res.data.data);
				setCount(res.data.total);
				setIsLoading(false);
			}
		} catch (err) {
			console.error("Error fetching users:", err);
			setIsLoading(false);
		}
	};

	const handleEditUser = async (user) => {
		setToEdit(user);
		navigate("/create-user");
	};

	const handleDeleteUser = async () => {
		setIsLoading(true);
		const headers = {};
		headers[constants.TOKEN_NAME] = token;
		try {
			let res = await axios.delete(
				`${process.env.REACT_APP_API_URL}/users/${toEdit._id}`,
				{ headers: headers }
			);
			if (res) {
				fetchUsers();
				setInfoMessage("User has been deleted successfully");
				setToEdit(null);
				setIsLoading(false);
				setIsConfirmOpen(false);
			}
		} catch (err) {
			console.error("Error deleting user:", err);
			setIsLoading(false);
			setErrorMessage("Error deleting user. Please try again.");
			setIsConfirmOpen(false);
		}
	};

	useEffect(() => {
		setFilteredUsers(
			users.filter((user) =>
				user.email.toLowerCase().includes(filter.toLowerCase())
			)
		);
	}, [filter, users]);

	useEffect(() => {
		fetchUsers(page, limit);
	}, [page, limit]);

	const handlePageChange = (event, newPage) => {
		setPage(newPage);
	};

	const handleLimitChange = (event) => {
		setLimit(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<>
			<Container>
				<CustomTypography text="Users" variant="h6" />

				{user.isAdmin && (
					<div style={{ textAlign: "right" }}>
						<CustomButton
							onClick={() => {
								setToEdit(null);
								navigate("/create-user");
							}}
							text="Add User"
							mx="0px"
							icon={<AddOutlined />}
						/>
					</div>
				)}

				<CustomTextField
					label={`Filter by email`}
					value={filter}
					onChange={(e) => setFilter(e.target.value)}
				/>

				{users && users.length > 0 ? (
					<>
						<TableContainer
							component={Paper}
							className="mt-2 overflow-hidden"
						>
							<Table className="table-sm">
								<TableHead>
									<TableRow>
										<TableCell>Emails</TableCell>
										<TableCell>Role</TableCell>
										<TableCell
											align="right"
											style={{ paddingRight: "12%" }}
										>
											Actions
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{filteredUsers.map((elem) => (
										<TableRow key={elem._id}>
											<TableCell>{elem.email}</TableCell>
											<TableCell>
												{elem.isAdmin
													? "Admin"
													: "User"}
											</TableCell>
											<TableCell align="right">
												{!elem.isAdmin &&
													user.isAdmin && (
														<CustomButton
															color="secondary"
															text="Change password"
															onClick={() => {
																setToEdit(elem);
																navigate(
																	`/change-password?userId=${elem._id}`
																);
															}}
														/>
													)}
												<CustomButton
													color="info"
													text="Edit"
													onClick={() =>
														handleEditUser(elem)
													}
												/>
												<CustomButton
													color="error"
													text="Delete"
													onClick={() => {
														setIsConfirmOpen(true);
														setToEdit(elem);
													}}
												/>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>

						<div className="mt-1">
							<TablePagination
								component="div"
								count={count}
								page={page}
								onPageChange={handlePageChange}
								rowsPerPage={limit}
								onRowsPerPageChange={handleLimitChange}
								rowsPerPageOptions={[10, 20, 30, 50]}
							/>
						</div>
					</>
				) : (
					<span>
						<i>No data found</i>
					</span>
				)}
			</Container>

			{isConfirmOpen && (
				<ConfirmDialog
					isConfirmOpen={isConfirmOpen}
					title="Are you sure?"
					body="Are you sure you want to delete this record? This action can't be undone."
					onNo={() => setIsConfirmOpen(false)}
					onYes={() => handleDeleteUser()}
				/>
			)}
		</>
	);
};

export default Users;
