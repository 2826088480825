import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";
import {
	CheckOutlined,
	CloseOutlined,
	QuestionMarkOutlined,
} from "@mui/icons-material";
import axios from "axios";
import constants from "../constants/constants";
import useStore from "../store/store";
import { CustomTextField, CustomButton, CustomTypography } from "../controls";
import { WebSocketDialog } from "../components";

const AddNewWebSocket = () => {
	const navigate = useNavigate();
	const {
		token,
		setIsLoading,
		toEdit,
		setToEdit,
		setSuccessMessage,
		setInfoMessage,
		setErrorMessage,
		user,
	} = useStore((state) => state);
	const [isTestOpen, setIsTestOpen] = useState(false);
	const [testData, setTestData] = useState([]);
	const [newWebsocket, setNewEndpoint] = useState(
		toEdit
			? toEdit
			: {
					_id: null,
					nickname: "",
					url: "",
					authorization: "",
			  }
	);

	useEffect(() => {
		if (!user.isAdmin) navigate("/websockets");
	}, [user.isAdmin, navigate]);

	const handleInputChange = (e) => {
		setNewEndpoint({ ...newWebsocket, [e.target.name]: e.target.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		const headers = {};
		headers[constants.TOKEN_NAME] = token;
		if (newWebsocket._id === null) {
			try {
				let res = await axios.post(
					`${process.env.REACT_APP_API_URL}/websockets`,
					newWebsocket,
					{ headers: headers }
				);
				if (res.status === 200) {
					handleResetWebsocket();
					setIsLoading(false);
					setSuccessMessage("WebSocket saved successfully");
					navigate("/websockets");
				}
			} catch (err) {
				setErrorMessage("Error creating webSocket. Please try again.");
				console.error("Error creating API webSocket:", err);
				setErrorMessage(err);
				setIsLoading(false);
			}
		} else {
			try {
				const res = await axios.put(
					`${process.env.REACT_APP_API_URL}/websockets/${newWebsocket._id}`,
					newWebsocket
				);
				if (res.status === 200) {
					handleResetWebsocket();
					setIsLoading(false);
					setToEdit(null);
					setInfoMessage("WebSocket updated successfully");
					navigate("/websockets");
				}
			} catch (err) {
				console.error("Error updating API webSocket:", err);
				setErrorMessage("Error updating webSocket. Please try again.");
				setIsLoading(false);
			}
		}
	};

	const handleResetWebsocket = () => {
		setNewEndpoint({
			_id: null,
			nickname: "",
			url: "",
			authorization: "",
		});
	};

	const handleTestWebsocket = async (id) => {
		setIsLoading(true);
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_API_URL}/websockets/${id}/test`,
				newWebsocket
			);
			setTestData(res.data);
			setIsTestOpen(true);
			setIsLoading(false);
		} catch (err) {
			console.error("Error testing API webSocket:", err);
			setIsLoading(false);
		}
	};

	return (
		<>
			<Container>
				<CustomTypography
					text={toEdit ? "Update WebSocket" : "Add New WebSocket"}
				/>
				<form className="mt-3">
					<div className="row">
						<div className="col-6">
							<CustomTextField
								label="Nickname"
								name="nickname"
								value={newWebsocket.nickname}
								onChange={handleInputChange}
								placeholder="Nickname"
								required
							/>
						</div>
						<div className="col-6">
							<CustomTextField
								label="Authorization"
								name="authorization"
								value={newWebsocket.authorization}
								onChange={handleInputChange}
								placeholder="Authorization"
							/>
						</div>
					</div>
					<CustomTextField
						label="URL"
						name="url"
						value={newWebsocket.url}
						onChange={handleInputChange}
						placeholder="URL"
						required
					/>
					<div className="row">
						<div className="col-4">
							<CustomButton
								text={
									newWebsocket._id === null
										? "Add WebSocket"
										: "Update WebSocket"
								}
								size="normal"
								onClick={handleSubmit}
								my={1}
								mx="0px"
								fullWidth
								icon={<CheckOutlined />}
							/>
						</div>
						<div className="col-4">
							<CustomButton
								text={
									newWebsocket._id === null
										? "Test before Save"
										: "Test before Update"
								}
								size="normal"
								onClick={() => handleTestWebsocket(null)}
								my={1}
								mx="0px"
								fullWidth
								icon={<QuestionMarkOutlined />}
							/>
						</div>
						<div className="col-4">
							<CustomButton
								text="Cancel"
								size="normal"
								onClick={() => {
									handleResetWebsocket();
									setToEdit(null);
									navigate("/websockets");
								}}
								my={1}
								mx="0px"
								fullWidth
								icon={<CloseOutlined />}
							/>
						</div>
					</div>
				</form>
			</Container>

			<WebSocketDialog
				isTestOpen={isTestOpen}
				title="WebSocket Test Results"
				testData={testData}
				handleClose={() => setIsTestOpen(false)}
			/>
		</>
	);
};

export default AddNewWebSocket;
